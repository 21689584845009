<template>
  <div class="section text-dark">
    <b-container fluid>
      <div
        v-if="
          detailSubscription &&
          detailSubscription.payment_status === 'pending' &&
          detailSubscription.xendit_payment &&
          detailSubscription.xendit_payment.payment_method === 'qr_code'
        "
      >
        <b-row align-h="center">
          <b-col xl="5" lg="6" md="8" sm="9" xs="12">
            <b-row class="mb-2">
              <b-col class="text-center">
                <div class="size20 fw-bold-800">Pindai Untuk Membayar</div>
                <img :src="qrCodeImage" alt="QR Code" />
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col class="size24 fw-bold-800"> Detail Transaksi </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col> ID Transaksi </b-col>
              <b-col class="text-right">
                {{ detailSubscription.invoice_number }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col> Status </b-col>
              <b-col class="text-right">
                <span class="bg-warning p-1 rounded-pill fw-bold-700">
                  {{
                    detailSubscription.payment_status === "pending"
                      ? "Menunggu Pembayaran"
                      : ""
                  }}
                </span>
              </b-col>
            </b-row>
            <b-row class="mb-2" v-if="detailSubscription.subscription_renewal">
              <b-col> Paket Berlangganan </b-col>
              <b-col class="text-right">
                {{ detailSubscription.subscription_renewal.renewal_name }}
              </b-col>
            </b-row>
            <!-- <b-row class="mb-2" v-if="detailSubscription.subscription_renewal">
              <b-col> Nominal </b-col>
              <b-col class="text-right">
                {{
                  detailSubscription.subscription_renewal.sell_price
                    | formatAmount
                }}
              </b-col>
            </b-row> -->
            <b-row class="mb-2" v-if="detailSubscription.total_price">
              <b-col> Nominal </b-col>
              <b-col class="text-right">
                {{ detailSubscription.total_price | formatAmount }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col> Metode Pembayaran </b-col>
              <b-col class="text-right">
                {{
                  detailSubscription.xendit_payment.payment_method === "bca_va"
                    ? "BCA Virtual Account - "
                    : "QRIS"
                }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col> Waktu Transaksi </b-col>
              <b-col class="text-right">
                {{ detailSubscription.xendit_payment.created_at }}
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col> Bayar Sebelum </b-col>
              <b-col class="text-right text-danger">
                {{ detailSubscription.xendit_payment.payment_expired_at }}
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col class="size24 fw-bold-800"> Cara Bayar </b-col>
            </b-row>
            <div class="card p-1" v-b-toggle.collapse-1 variant="primary">
              <div class="d-flex align-items-center">
                <b-img
                  :src="require('/src/assets/images/logo/qris.png')"
                  alt="qris"
                  style="width: 4rem"
                />
                <span class="ml-1"> QRIS </span>
                <span class="ml-auto">
                  <feather-icon icon="ChevronDownIcon" size="16" />
                </span>
              </div>
              <b-collapse id="collapse-1">
                <hr />
                <ol>
                  <li>
                    Buka OVO, Gojek, Dana, Link Aja, atau aplikasi
                    mobile-banking yang Anda miliki
                  </li>
                  <li>Scan QR Code tersebut</li>
                  <li>Input jumlah nominal yang ingin dibayarkan</li>
                  <li>Pilih bayar dan masukkan nama lengkap Anda</li>
                  <li>Transaksi Anda telah selesai</li>
                </ol>
              </b-collapse>
            </div>
          </b-col>
        </b-row>
      </div>
      <div
        v-else-if="
          detailSubscription &&
          detailSubscription.payment_status === 'success' &&
          detailSubscription.xendit_payment &&
          detailSubscription.xendit_payment.payment_method === 'qr_code'
        "
      >
        <b-row align-h="center">
          <b-col xl="5" lg="6" md="8" sm="9" xs="12">
            <b-row class="mb-2"> </b-row>
            <b-row class="mb-1">
              <b-col class="size24 fw-bold-800"> Detail Berlangganan </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col> ID Transaksi </b-col>
              <b-col class="text-right" @click="print">
                <b-button class="bg-white border-8">
                  {{ detailSubscription.invoice_number }}
                </b-button>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col> Status </b-col>
              <b-col class="text-right">
                <span class="bg-primary p-1 rounded-pill fw-bold-700">
                  {{
                    detailSubscription.payment_status === "success"
                      ? "Aktif"
                      : ""
                  }}
                </span>
              </b-col>
            </b-row>
            <b-row class="mb-2" v-if="detailSubscription.subscription_renewal">
              <b-col> Paket Berlangganan </b-col>
              <b-col class="text-right">
                {{ detailSubscription.subscription_renewal.renewal_name }}
              </b-col>
            </b-row>
            <!-- <b-row class="mb-2" v-if="detailSubscription.subscription_renewal">
              <b-col> Nominal </b-col>
              <b-col class="text-right">
                {{
                  detailSubscription.subscription_renewal.sell_price
                    | formatAmount
                }}
              </b-col>
            </b-row> -->
            <b-row class="mb-2" v-if="detailSubscription.total_price">
              <b-col> Nominal </b-col>
              <b-col class="text-right">
                {{ detailSubscription.total_price | formatAmount }}
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col> Berlaku Hingga </b-col>
              <b-col class="text-right">
                {{ detailSubscription.payment_expired_at }}
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div
        v-else-if="
          detailSubscription &&
          detailSubscription.payment_status === 'canceled' &&
          detailSubscription.xendit_payment &&
          detailSubscription.xendit_payment.payment_method === 'qr_code'
        "
      >
        <b-row align-h="center">
          <b-col xl="5" lg="6" md="8" sm="9" xs="12">
            <b-row class="mb-2"> </b-row>
            <b-row class="mb-1">
              <b-col class="size24 fw-bold-800"> Detail Berlangganan </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col> ID Transaksi </b-col>
              <b-col class="text-right">
                {{ detailSubscription.invoice_number }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col> Status </b-col>
              <b-col class="text-right">
                <span class="bg-danger p-1 rounded-pill fw-bold-700 text-white">
                  {{
                    detailSubscription.payment_status === "canceled"
                      ? "Dibatalkan"
                      : ""
                  }}
                </span>
              </b-col>
            </b-row>
            <b-row class="mb-2" v-if="detailSubscription.subscription_renewal">
              <b-col> Paket Berlangganan </b-col>
              <b-col class="text-right">
                {{ detailSubscription.subscription_renewal.renewal_name }}
              </b-col>
            </b-row>
            <!-- <b-row class="mb-2" v-if="detailSubscription.subscription_renewal">
              <b-col> Nominal </b-col>
              <b-col class="text-right">
                {{
                  detailSubscription.subscription_renewal.sell_price
                    | formatAmount
                }}
              </b-col>
            </b-row> -->
            <b-row class="mb-2" v-if="detailSubscription.total_price">
              <b-col> Nominal </b-col>
              <b-col class="text-right">
                {{ detailSubscription.total_price | formatAmount }}
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col> Berlaku Hingga </b-col>
              <b-col class="text-right">
                {{ detailSubscription.payment_expired_at }}
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div
        v-else-if="
          detailSubscription &&
          detailSubscription.payment_status === 'expired' &&
          detailSubscription.xendit_payment &&
          detailSubscription.xendit_payment.payment_method === 'qr_code'
        "
      >
        <b-row align-h="center">
          <b-col xl="5" lg="6" md="8" sm="9" xs="12">
            <b-row class="mb-2"> </b-row>
            <b-row class="mb-1">
              <b-col class="size24 fw-bold-800"> Detail Berlangganan </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col> ID Transaksi </b-col>
              <b-col class="text-right">
                {{ detailSubscription.invoice_number }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col> Status </b-col>
              <b-col class="text-right">
                <span
                  class="secondary-2 p-1 rounded-pill fw-bold-700 text-white"
                >
                  {{
                    detailSubscription.payment_status === "expired"
                      ? "Kedaluwarsa"
                      : ""
                  }}
                </span>
              </b-col>
            </b-row>
            <b-row class="mb-2" v-if="detailSubscription.subscription_renewal">
              <b-col> Paket Berlangganan </b-col>
              <b-col class="text-right">
                {{ detailSubscription.subscription_renewal.renewal_name }}
              </b-col>
            </b-row>
            <!-- <b-row class="mb-2" v-if="detailSubscription.subscription_renewal">
              <b-col> Nominal </b-col>
              <b-col class="text-right">
                {{
                  detailSubscription.subscription_renewal.sell_price
                    | formatAmount
                }}
              </b-col>
            </b-row> -->
            <b-row class="mb-2" v-if="detailSubscription.total_price">
              <b-col> Nominal </b-col>
              <b-col class="text-right">
                {{ detailSubscription.total_price | formatAmount }}
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col> Berlaku Hingga </b-col>
              <b-col class="text-right">
                {{ detailSubscription.payment_expired_at }}
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </b-container>
    <InvoiceSubscription
      :detailSubscription="detailSubscription"
      class="d-none"
    />
  </div>
</template>

<script>
import {
  BModal,
  BContainer,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BCard,
  BButton,
  BFormCheckbox,
  BFormRadio,
  BCollapse,
  VBToggle,
} from "bootstrap-vue";
import QRCode from "qrcode";

import {
  checkPermission,
  successNotification,
  formatRupiah,
  preformatFloat,
} from "@/auth/utils";

import ModalHeader from "@/components/ModalHeader.vue";
import InvoiceSubscription from "@/components/InvoiceSubscription.vue";

export default {
  components: {
    ModalHeader,
    BModal,
    formatRupiah,
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BCard,
    BButton,
    BFormCheckbox,
    BFormRadio,
    BCollapse,
    InvoiceSubscription,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    detailSubscription: Object,
  },
  data() {
    return {
      qrCodeImage: "",
    };
  },
  watch: {
    detailSubscription(value) {
      if (value.xendit_payment.qr_string) {
        this.generateQRCode();
      }
    },
  },
  methods: {
    generateQRCode() {
      const dummyQRCodeData = this.detailSubscription.xendit_payment.qr_string;
      const self = this;
      QRCode.toDataURL(dummyQRCodeData, function (error, url) {
        if (error) {
          console.error("Error generating QR code:", error);
        } else {
          self.qrCodeImage = url;
        }
      });
    },
    async print() {
      const { hostname, protocol, port } = window.location;

      const noLandscapeOptions = {
        name: "_blank",
        specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
        styles: [
          `${protocol}//${hostname}${
            port !== "" ? `:${port}` : ""
          }/bootstrap.css`,
        ],
        timeout: 1000,
        autoClose: true,
        windowTitle: window.document.title,
      };

      await this.$htmlToPaper("contentPrintSubscription", noLandscapeOptions);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";

.secondary-2 {
  background-color: $secondary-2;
}
</style>
